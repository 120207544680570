import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import getIcon from '../utils/getIcon';
import './FlightDateRangePicker.scss';

const DEFAULT_CLASS = 'flight-date-range-picker';

const FlightDateRangePicker = (props) => {
  const {
    onChange, value, format, ampm, disabled, disableFuture,
    disablePast, minDate, maxDate, label, isError,
    showTodayButton, minutesStep, allowKeyboardControl,
  } = props;
  const pickerProps = {
    value,
    onChange,
    ampm,
    disabled,
    disableFuture,
    disablePast,
    showTodayButton,
    minutesStep,
    allowKeyboardControl,
    minDateMessage: '',
    maxDateMessage: '',
    invalidDateMessage: '',
    startText: '',
    endText: '',
  };
  if (minDate) {
    pickerProps.minDate = minDate;
  }
  if (maxDate) {
    pickerProps.maxDate = maxDate;
  }

  const handleRenderInput = (startProps, endProps) => {
    const textFieldProps = { ...startProps };
    let displayValue = '';
    if (textFieldProps.inputProps && textFieldProps.inputProps.value) {
      const startDate = new Date(textFieldProps.inputProps.value);
      if (Number.isNaN(startDate.getTime())) {
        displayValue += 'Invalid Date';
      } else {
        displayValue += moment.utc(startDate).format(format);
      }
    }
    if (displayValue && endProps.inputProps && endProps.inputProps.value) {
      const endDate = new Date(endProps.inputProps.value);
      if (Number.isNaN(endDate.getTime())) {
        displayValue += ' - Invalid Date';
      } else {
        displayValue += ` - ${moment.utc(endDate).format(format)}`;
      }
    }
    if (textFieldProps.inputProps) {
      textFieldProps.inputProps = {
        ...textFieldProps.inputProps,
        value: displayValue || label,
        readOnly: true,
      };
    }
    textFieldProps.variant = 'standard';
    return (
      <React.Fragment>
        <TextField {...textFieldProps} />
        {getIcon('downCarrot', { className: `${DEFAULT_CLASS}__icon` })}
      </React.Fragment>
    );
  };

  let mainClass = DEFAULT_CLASS;
  mainClass += isError ? ` ${DEFAULT_CLASS}--error` : '';

  return (
    <div className={mainClass}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DateRangePicker
          {...pickerProps}
          renderInput={handleRenderInput}
        />
      </LocalizationProvider>
    </div>
  );
};

FlightDateRangePicker.propTypes = {
  value: PropTypes.arrayOf(Date),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  ampm: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isError: PropTypes.bool,
  showTodayButton: PropTypes.bool,
  minutesStep: PropTypes.number,
  allowKeyboardControl: PropTypes.bool,
};

FlightDateRangePicker.defaultProps = {
  value: null,
  label: 'Enter date range',
  format: 'MMM DD, YYYY',
  ampm: true,
  disabled: false,
  disableFuture: false,
  disablePast: false,
  minDate: null,
  maxDate: null,
  isError: false,
  showTodayButton: true,
  minutesStep: 5,
  allowKeyboardControl: true,
};

export default FlightDateRangePicker;
