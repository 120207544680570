import React from 'react';

const SvgCamera = (props) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5762 13.5439C15.3628 13.5439 16 12.9068 16 12.1201L15.9928 4.008C15.9928 3.22135 15.3557 2.5842 14.569 2.5842H12.9901C12.2051 2.5842 11.5376 2.01112 11.4189 1.23515C11.2872 0.398663 10.4578 0 9.77083 0H6.22913C5.54926 0 4.71634 0.395104 4.58108 1.23515C4.45808 2.0121 3.78828 2.5842 3.00166 2.5842H1.4238C0.637151 2.5842 0 3.22135 0 4.008V12.1201C0 12.9068 0.637151 13.5439 1.4238 13.5439H14.5762ZM7.99194 3.40289C5.72098 3.40289 3.88071 5.24315 3.88071 7.51412C3.88071 9.78508 5.72098 11.6253 7.99194 11.6253C10.2629 11.6253 12.1032 9.78508 12.1032 7.51412C12.1032 5.24315 10.2629 3.40289 7.99194 3.40289ZM7.99194 10.0627C6.58593 10.0627 5.44333 8.92012 5.44333 7.51412C5.44333 6.10811 6.58593 4.96551 7.99194 4.96551C9.39794 4.96551 10.5405 6.10811 10.5405 7.51412C10.5405 8.92012 9.39794 10.0627 7.99194 10.0627ZM13.7405 4.13974L13.7404 4.13974C13.3738 4.13619 13.0784 4.43163 13.0748 4.79825C13.0713 5.16488 13.3667 5.46032 13.7333 5.46388C14.0964 5.46744 14.3954 5.172 14.399 4.80893C14.4025 4.44349 14.1125 4.14524 13.7476 4.13979L13.7405 4.13974Z"
      fill="#051336"
    />
  </svg>
);

export default SvgCamera;
