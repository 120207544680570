/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../constants';
import FlightButton from '../flight-button/FlightButton';
import './FlightModal.scss';

const DEFAULT_CLASS = 'flight-modal';
const COMPONENT_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const FlightModal = (props) => {
  const {
    isVisible, toggleModalShown, scrollable, size,
    warning, className, header, content, footer,
  } = props;
  const closeRef = useRef(null);
  const endRef = useRef(null);
  const handleTrapFocusForward = (event) => {
    if (!event.shiftKey && event.key === EVENT_KEYS.TAB) {
      event.preventDefault();
      closeRef.current.focus();
    }
  };
  const handleTrapFocusBackward = (event) => {
    if (event.shiftKey && event.key === EVENT_KEYS.TAB) {
      event.preventDefault();
      endRef.current.focus();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add(
        `${DEFAULT_CLASS}-wrapper--scroll-lock`,
      );
      if (closeRef && closeRef.current && endRef && endRef.current) {
        closeRef.current.focus();
        closeRef.current.addEventListener(
          'keydown', handleTrapFocusBackward, false,
        );
        endRef.current.addEventListener(
          'keydown', handleTrapFocusForward, false,
        );
      }
    }
    return () => {
      document.body.classList.remove(
        `${DEFAULT_CLASS}-wrapper--scroll-lock`,
      );
      if (closeRef && closeRef.current && endRef && endRef.current) {
        closeRef.current.removeEventListener(
          'keydown', handleTrapFocusBackward,
        );
        endRef.current.removeEventListener(
          'keydown', handleTrapFocusForward,
        );
      }
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const wrapperClass = `${DEFAULT_CLASS}-wrapper`;
  let closeModalButtonClass = `${DEFAULT_CLASS}__header__close`;
  let mainClass = DEFAULT_CLASS;
  let sizeModifier = `${DEFAULT_CLASS}--small`;
  if (scrollable) {
    mainClass += ` ${DEFAULT_CLASS}--scrollable`;
  }
  switch (size) {
    case COMPONENT_SIZE.LARGE:
      sizeModifier = `${DEFAULT_CLASS}--large`;
      break;
    case COMPONENT_SIZE.MEDIUM:
      sizeModifier = `${DEFAULT_CLASS}--medium`;
      break;
    case COMPONENT_SIZE.SMALL:
    default:
      sizeModifier = `${DEFAULT_CLASS}--small`;
  }
  mainClass += ` ${sizeModifier}`;
  if (warning) {
    closeModalButtonClass += ` ${closeModalButtonClass}--warning`;
    mainClass += ` ${DEFAULT_CLASS}--warning`;
  }
  mainClass += className ? ` ${className}` : '';

  return (
    <div className={wrapperClass}>
      <div className={`${DEFAULT_CLASS}-wrapper__overlay-shadow`} />
      <div className={mainClass}>
        <div className={`${DEFAULT_CLASS}__header`}>
          {header}
          <FlightButton
            theme="minor"
            onClick={toggleModalShown}
            iconLeft="clear"
            className={closeModalButtonClass}
            buttonRef={closeRef}
            ariaLabel="Close"
          />
        </div>
        <div className={`${DEFAULT_CLASS}__content`}>
          {content}
        </div>
        <div className={`${DEFAULT_CLASS}__footer`}>
          {footer}
        </div>
      </div>
      <div
        className={`${DEFAULT_CLASS}-wrapper__end`}
        ref={endRef}
        tabIndex={0}
      />
    </div>
  );
};

FlightModal.propTypes = {
  isVisible: PropTypes.bool,
  toggleModalShown: PropTypes.func,
  scrollable: PropTypes.bool,
  size: PropTypes.string,
  warning: PropTypes.bool,
  className: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};

FlightModal.defaultProps = {
  isVisible: false,
  toggleModalShown: () => undefined,
  scrollable: false,
  size: 'small',
  warning: false,
  className: '',
  header: null,
  content: null,
  footer: null,
};

export default FlightModal;
