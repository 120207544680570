export const CHECKBOX_STATES = {
  UNSELECTED: 'UNSELECTED',
  INDETERMINATE: 'INDETERMINATE',
  SELECTED: 'SELECTED',
};

export const EVENT_KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  TAB: 'Tab',
};
