import React from 'react';
import PropTypes from 'prop-types';
import FlightTooltip from '../../flight-tooltip/FlightTooltip';
import './FlightTableHeader.scss';

const DEFAULT_CLASS = 'flight-table-header';

const FlightTableHeader = (props) => {
  const {
    selected, onClick, name, isVisible, icon,
    isSortable, hideTooltip, tooltipDelay,
  } = props;

  let mainClass = DEFAULT_CLASS;
  mainClass += isSortable ? ` ${DEFAULT_CLASS}--sortable` : '';
  mainClass += onClick && selected ? ` ${DEFAULT_CLASS}--selected` : '';

  return isVisible && (
    <th className={mainClass} onClick={isSortable ? onClick : () => undefined}>
      {hideTooltip ? name : (
        <FlightTooltip
          isEnabled
          description={name}
          delay={tooltipDelay}
        >
          {name}
        </FlightTooltip>
      )}
      {icon}
    </th>
  );
};

FlightTableHeader.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  isVisible: PropTypes.bool,
  icon: PropTypes.element,
  isSortable: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  tooltipDelay: PropTypes.number,
};

FlightTableHeader.defaultProps = {
  selected: false,
  onClick: () => undefined,
  name: '',
  isVisible: false,
  icon: null,
  isSortable: false,
  hideTooltip: false,
  tooltipDelay: 400,
};

export default FlightTableHeader;
