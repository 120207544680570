/**
 *
 *
 * @export ApiHelper
 * @param {*} args = {url: '', method: '', type:'', token:'', data: {}}
 * @returns response
 */
export default function ApiHelper(args) {
  const response = fetch(args.url, {
    method: args.method,
    headers: {
      'Content-Type': args.type,
      'X-Authorization': args.token,
    },
    body: args.data,
  });
  return response;
}
