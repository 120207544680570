import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { TimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import './FlightTimePicker.scss';

const DEFAULT_CLASS = 'flight-time-picker';

const FlightTimePicker = (props) => {
  const {
    onChange, value, ampm, autoOk, disabled, disableToolbar, format,
    isError, label, minutesStep, onClose, onOpen, readOnly,
  } = props;

  const pickerProps = {
    onChange,
    value,
    ampm,
    autoOk,
    disabled,
    disableToolbar,
    inputFormat: format,
    isError,
    label,
    minutesStep,
    onClose,
    onOpen,
    readOnly,
  };

  let mainClass = DEFAULT_CLASS;
  mainClass += value === null ? ` ${DEFAULT_CLASS}--empty` : '';
  mainClass += isError ? ` ${DEFAULT_CLASS}--error` : '';

  return (
    <div className={mainClass}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <TimePicker
          {...pickerProps}
          renderInput={(inputProps) => <TextField {...inputProps} />}
        />
      </LocalizationProvider>
    </div>
  );
};

FlightTimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  ampm: PropTypes.bool,
  autoOk: PropTypes.bool,
  disabled: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  format: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.string,
  minutesStep: PropTypes.number,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  readOnly: PropTypes.bool,
};

FlightTimePicker.defaultProps = {
  value: null,
  ampm: true,
  autoOk: false,
  disabled: false,
  disableToolbar: false,
  format: 'hh:mm a',
  isError: false,
  label: '',
  minutesStep: 5,
  onClose: () => undefined,
  onOpen: () => undefined,
  readOnly: false,
};

export default FlightTimePicker;
