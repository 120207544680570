import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FlightTooltip.scss';

const DEFAULT_CLASS = 'flight-tooltip';
const TOOLTIP_CLASS = `${DEFAULT_CLASS}__tooltip`;

const FlightTooltip = (props) => {
  const {
    children, description, isEnabled,
    className, direction, maxWidth, delay,
  } = props;
  const [isShown, setIsShown] = useState(false);
  let timeOutId = null;
  const tooltipRef = (element) => {
    if (element) {
      // Resize component width based on props.width
      /* eslint-disable no-param-reassign */
      element.style.maxWidth = maxWidth;
    }
  };
  const handleEnterTooltip = () => {
    timeOutId = setTimeout(() => {
      setIsShown(true);
    }, delay);
  };
  const handleLeaveTooltip = () => {
    setIsShown(false);
    clearTimeout(timeOutId);
  };

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';
  let mainClass = TOOLTIP_CLASS;
  mainClass += ` ${TOOLTIP_CLASS}--${direction}`;
  mainClass += isEnabled && isShown ? ` ${TOOLTIP_CLASS}--show` : '';

  return (
    <div className={wrapperClass}>
      <div
        className={`${DEFAULT_CLASS}__content`}
        onFocus={handleEnterTooltip}
        onBlur={handleLeaveTooltip}
        onMouseEnter={handleEnterTooltip}
        onMouseLeave={handleLeaveTooltip}
        tabIndex={0}
      >
        {children}
      </div>
      <div className={mainClass} ref={tooltipRef}>
        {description}
      </div>
    </div>
  );
};

FlightTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  className: PropTypes.string,
  isEnabled: PropTypes.bool,
  direction: PropTypes.string,
  maxWidth: PropTypes.string,
  delay: PropTypes.number,
};

FlightTooltip.defaultProps = {
  className: '',
  isEnabled: true,
  direction: 'bottom',
  maxWidth: '260px',
  delay: 400,
};

export default FlightTooltip;
