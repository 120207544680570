import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import './FlightDateTimePicker.scss';

const DEFAULT_CLASS = 'flight-date-time-picker';

const FlightDateTimePicker = (props) => {
  const {
    onChange, value, format, ampm, disabled, disableFuture,
    disablePast, minDate, maxDate, label, readOnly, isError,
    showTodayButton, minutesStep, allowKeyboardControl,
  } = props;

  const pickerProps = {
    value,
    onChange,
    label,
    readOnly,
    inputFormat: format,
    ampm,
    disabled,
    disableFuture,
    disablePast,
    showTodayButton,
    minutesStep,
    allowKeyboardControl,
    minDateMessage: '',
    maxDateMessage: '',
    invalidDateMessage: '',
  };
  if (minDate) {
    pickerProps.minDate = minDate;
  }
  if (maxDate) {
    pickerProps.maxDate = maxDate;
  }

  let mainClass = DEFAULT_CLASS;
  mainClass += isError ? ` ${DEFAULT_CLASS}--error` : '';

  return (
    <div className={mainClass}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DateTimePicker
          {...pickerProps}
          renderInput={(inputProps) => <TextField {...inputProps} />}
        />
      </LocalizationProvider>
    </div>
  );
};

FlightDateTimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  ampm: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isError: PropTypes.bool,
  readOnly: PropTypes.bool,
  showTodayButton: PropTypes.bool,
  minutesStep: PropTypes.number,
  allowKeyboardControl: PropTypes.bool,
};

FlightDateTimePicker.defaultProps = {
  value: null,
  label: '',
  format: 'yyyy-MM-dd hh:mm a',
  ampm: true,
  disabled: false,
  disableFuture: false,
  disablePast: false,
  minDate: null,
  maxDate: null,
  isError: false,
  readOnly: false,
  showTodayButton: true,
  minutesStep: 5,
  allowKeyboardControl: true,
};

export default FlightDateTimePicker;
