import React, { useState, useEffect } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import './FlightImagePicker.scss';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import getIcon from '../utils/getIcon';
import FlightButton from '../flight-button/FlightButton';
import FlightTextInput from '../flight-text-input/FlightTextInput';
import FlightModal from '../flight-modal/FlightModal';

import ImagePlaceHolder from '../../public/assets/images/image-placeholder.svg';

import ApiHelper from './ApiHelper';

const FlightImagePicker = (props) => {
  const {
    isVisible, toggleImagePicker, pixelPusherUrl, fileManagerUrl,
    token, selectedImage, optionalHeight, optionalWidth,
  } = props;

  const validateImageUrl = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/gs;
  const [tabState, setTabState] = useState(0);
  const [page, setPage] = useState(0);
  const [uploadFromUrl, setUploadFromUrl] = useState('');
  const [errorState, setErrorState] = useState('');
  const [invalidImageUrlMessage, setInvalidImageUrlMessage] = useState('');
  const [selectedImg, setSelectedImg] = useState();
  const [libraryImages, setLibraryImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const SUCCESS_RESPONSE = 200;
  const LIMIT = 20;

  // function that fetches existing or new images stored in s3 bucket
  // using user specified link & token.
  async function getFilesFromLibrary(incrementPage) {
    const args = {
      url: `${fileManagerUrl}/files?extensions=.jpg,.jpeg,.svg,.png,.gif&limit=${LIMIT}&offset=${incrementPage}&sortby=uploadedAt&sortorder=desc`,
      method: 'GET',
      type: 'application/json',
      token,
    };
    const response = await ApiHelper(args);
    return response.json();
  }

  const fetchFromLib = async (startPage, initial) => {
    const resp = await getFilesFromLibrary(startPage);
    try {
      setLibraryImages(() => ({
        data: resp.data,
        total: resp.pagination && resp.pagination.totalRecords,
      }));
      setLoading(false);
      setTabState(initial ? 0 : 1);
    } catch (e) {
      setErrorState(e.error && e.error.exceptionMessage);
    }
    return 'done';
  };

  // function that performs direct upload to s3 bucket
  async function uploadFileToS3(url, data) {
    setLoading(true);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-Authorization': token,
      },
      body: data,
    });
    if (response.status === SUCCESS_RESPONSE) {
      fetchFromLib(0);
    } else {
      setErrorState(response.error && response.error.exceptionMessage);
      setLoading(false);
    }
  }

  // function that uses pixelPusherApi to upload from link
  async function uploadFileFromUrl(url, data) {
    setErrorState('');
    setLoading(true);
    const params = {
      proxyTarget: data.proxyTarget,
      proxyHeaders: { 'x-authorization': data.token },
      proxyFileParam: 'file',
      proxyParams: {
        isPrivate: false,
      },
      image: url,
    };
    // if user specifies any optionalHeight or optionalWidth set that before sending to pixelPusher
    if (optionalWidth !== 0 && optionalHeight !== 0) {
      Object.assign(params, { resize: { width: optionalWidth, height: optionalHeight } });
    } else if (optionalWidth !== 0) {
      Object.assign(params, { resize: { width: optionalWidth } });
    } else if (optionalHeight !== 0) {
      Object.assign(params, { resize: { height: optionalHeight } });
    }

    const args = {
      url: `${pixelPusherUrl}/transform`,
      method: 'POST',
      type: 'application/json',
      token,
      data: JSON.stringify(params),
    };

    const response = await ApiHelper(args);
    if (response.status === SUCCESS_RESPONSE) {
      fetchFromLib(0);
    } else {
      setErrorState(response.error && response.error.exceptionMessage);
      setLoading(false);
    }
  }

  // validate file upload
  const validateUploadedImage = (file, uploadedFiles) => {
    const img = new Image();
    const url = window.URL || window.webkitURL;
    const objectUrl = url.createObjectURL(file);
    img.src = objectUrl;
    img.onload = function SetImg() {
      url.revokeObjectURL(objectUrl);
      if (img.width === optionalWidth || img.height === optionalHeight) {
        uploadFileToS3(
          `${fileManagerUrl}/files/upload`,
          uploadedFiles,
        );
      } else {
        setErrorState(`Error uploading, image must be ${optionalHeight !== 0 ? `${optionalHeight}Height` : ''} ${optionalWidth !== 0 ? `${optionalWidth}Width` : ''}`);
      }
    };
  };

  // create file for upload
  const onChangeHandler = async (file) => {
    setErrorState('');
    const uploadedFiles = new FormData();
    uploadedFiles.append('file', file.target.files[0]);
    if (optionalHeight !== 0 || optionalWidth !== 0) {
      validateUploadedImage(file.target.files[0], uploadedFiles);
    } else {
      uploadFileToS3(
        `${fileManagerUrl}/files/upload`,
        uploadedFiles,
      );
    }
  };

  // setSelected after fetch after upload
  useEffect(() => {
    setSelectedImg(libraryImages.data && libraryImages.data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading && libraryImages]);

  useEffect(() => {
    if (libraryImages.length === 0 && isVisible) {
      fetchFromLib(0, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (uploadFromUrl) {
      const isUrlValid = uploadFromUrl.match(validateImageUrl);
      if (isUrlValid) {
        setInvalidImageUrlMessage('');
      } else {
        setInvalidImageUrlMessage('Invalid Image URL');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFromUrl]);

  const handleUploadFromUrl = () => {
    const data = {
      proxyTarget:
        `${fileManagerUrl}/files/upload`,
      token,
    };
    uploadFileFromUrl(uploadFromUrl, data);
  };

  const handleClick = (item) => {
    setSelectedImg(item);
  };

  // reset all local states
  const resetDeps = () => {
    setSelectedImg('');
    setErrorState('');
    setUploadFromUrl('');
    setInvalidImageUrlMessage('');
    setTabState(0);
    setPage(0);
    setLibraryImages([]);
    toggleImagePicker();
  };

  const handleCancel = () => {
    resetDeps();
  };

  const handleSelectImage = () => {
    selectedImage(selectedImg);
    resetDeps();
  };

  // used for pagination
  const handleFetchMoreData = async () => {
    const incrementPage = page + 20;
    setTimeout(async () => {
      const resp = await getFilesFromLibrary(incrementPage);
      try {
        setLibraryImages(() => ({
          data: [...libraryImages.data, ...resp.data],
          total: resp.pagination && resp.pagination.totalRecords,
        }));
      } catch (e) {
        setErrorState(e.error && e.error.exceptionMessage);
      }
      setPage(incrementPage);
    }, 300);
  };

  const RenderRestrictions = () => {
    if (optionalWidth !== 0 && optionalHeight !== 0) {
      return (<div>{`Image must be ${optionalHeight}px Height by ${optionalWidth}px Width`}</div>);
    } if (optionalHeight !== 0) {
      return (<div>{`Image must be ${optionalHeight}px Height`}</div>);
    } if (optionalWidth !== 0) {
      return (<div>{`Image must be ${optionalWidth}px Width`}</div>);
    }
    return null;
  };

  return (
    <div className="flight-image-picker">
      <FlightModal
        isVisible={isVisible}
        size="medium"
        scrollable
        toggleModalShown={() => handleCancel()}
        header={(
          <div className="flight-image-picker__header">
            {getIcon('camera', { className: 'flight-image-picker__header-icon' })}
            <h4> Choose Image </h4>
          </div>
        )}
        content={(
          <div className="flight-image-picker__content">
            <div className="flight-image-picker__content__error">
              {errorState}
            </div>
            {tabState === 0
              ? (
                <div className="flight-image-picker__content__restriction">
                  <RenderRestrictions />
                </div>
              ) : null}
            {!loading ? (
              <Tabs
                className="flight-tab"
                selectedTabClassName="flight-tab--selected"
                selectedIndex={tabState}
                onSelect={(tabIndex) => { setTabState(tabIndex); setErrorState(''); }}
              >
                <TabList className="flight-tab--list">
                  <Tab key="tab-upload">Upload</Tab>
                  <Tab key="tab-lib">Library</Tab>
                </TabList>
                <TabPanel className="flight-image-picker__content__container-left">
                  <div>
                    <div className="flight-image-picker__content__container-left__upload-btn">
                      <label htmlFor="fileUpload" className="flight-image-picker-upload-btn">
                        <input
                          type="file"
                          required
                          id="fileUpload"
                          accept="image/*"
                          onChange={(e) => onChangeHandler(e)}
                        />
                        <span>Upload from Computer</span>
                      </label>
                    </div>
                    <div className="flight-image-picker__content__container-left__or">
                      or
                    </div>
                    <div className="flight-image-picker__content__container-left__text">
                      Upload by URL
                    </div>
                    <FlightTextInput
                      placeholderText="Paste Image URL"
                      name="upload-by-url"
                      className="flight-image-picker__content__container-left__text-input"
                      hasError={invalidImageUrlMessage !== ''}
                      errorMessage={invalidImageUrlMessage}
                      value={uploadFromUrl}
                      width="367px"
                      onChange={(e) => setUploadFromUrl(e.target.value)}
                    />
                    {uploadFromUrl !== '' && invalidImageUrlMessage === '' ? (
                      <FlightButton
                        theme="primary"
                        className="flight-image-picker__content__container-left__upload-uri-btn"
                        label="Upload Image"
                        onClick={() => handleUploadFromUrl()}
                      />
                    ) : null}
                  </div>
                </TabPanel>
                <TabPanel className="flight-image-picker__content__container-right">
                  <InfiniteScroll
                    pageStart={page}
                    initialLoad={false}
                    loadMore={handleFetchMoreData}
                    hasMore={
                     libraryImages.total
                     > (libraryImages.data instanceof Array && libraryImages.data.length)
                    }
                    threshold={20}
                    element="div"
                    useWindow={false}
                    loader={
                      tabState !== 0 ? (
                        <div
                          className="flight-image-picker__content__container-left__loader"
                          key={0}
                        >
                          <CircularProgress size={20} />
                          <span className="flight-image-picker__content__container-left__loader-text">
                            Loading...
                          </span>
                        </div>
                      ) : (
                        <div key={1} />
                      )
                    }
                  >
                    <div className="flight-image-picker__content__container-right__img-lib">
                      {(libraryImages.data instanceof Array
                        && libraryImages.data.map((item) => (
                          <div
                            key={`image-key-${item.id}`}
                            onClick={() => handleClick(item)}
                            onKeyPress={() => handleClick(item)}
                            role="option"
                            aria-selected={item.friendlyName}
                            tabIndex={0}
                          >
                            <img
                              loading="lazy"
                              className={
                               (selectedImg
                                && selectedImg.id) === (item
                                && item.id)
                                 ? 'flight-image-picker__content__container-right__img-lib__tile--selected'
                                 : 'flight-image-picker__content__container-right__img-lib__tile'
                              }
                              width="110.05px"
                              height="111px"
                              style={{
                                backgroundImage: `url(${ImagePlaceHolder})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                              src={item.url}
                              alt={item.friendlyName}
                            />
                          </div>
                        )))}
                    </div>
                  </InfiniteScroll>
                </TabPanel>
              </Tabs>
            ) : (
              <div className="flight-image-picker__content__loading-state">
                {getIcon('loading', { className: 'flight-image-picker__content__loading-state__spinner' })}
                <div className="flight-image-picker__content__loading-state__loading-text">
                  Uploading..
                </div>
              </div>
            )}
          </div>
        )}
        footer={(
          <div className="flight-image-picker__footer">
            <FlightButton
              theme="secondary"
              label="Cancel"
              onClick={() => handleCancel()}
            />
            {tabState === 1 ? (
              <FlightButton
                theme="primary"
                label="Select Image"
                disabled={!selectedImg}
                onClick={() => handleSelectImage()}
              />
            ) : null}
          </div>
        )}
      />
    </div>
  );
};

FlightImagePicker.propTypes = {
  fileManagerUrl: PropTypes.string,
  isVisible: PropTypes.bool,
  optionalHeight: PropTypes.number,
  optionalWidth: PropTypes.number,
  pixelPusherUrl: PropTypes.string,
  selectedImage: PropTypes.func,
  toggleImagePicker: PropTypes.func,
  token: PropTypes.string,
};

FlightImagePicker.defaultProps = {
  fileManagerUrl: null,
  isVisible: false,
  optionalHeight: 0,
  optionalWidth: 0,
  pixelPusherUrl: null,
  selectedImage: (image) => image,
  toggleImagePicker: () => undefined,
  token: null,
};

export default FlightImagePicker;
