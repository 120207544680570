import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import FlightAvatar from '../flight-avatar/FlightAvatar';
import FlightButton from '../flight-button/FlightButton';
import FlightDropdown from '../flight-dropdown/FlightDropdown';
import FlightTag from '../flight-tag/FlightTag';
import FlightTooltip from '../flight-tooltip/FlightTooltip';
import './FlightNav.scss';

const DEFAULT_CLASS = 'flight-nav';
const HEADER_CLASS = `${DEFAULT_CLASS}__header`;
const SIDEBAR_CLASS = `${DEFAULT_CLASS}__sidebar`;
const ROUTE_CONTENT_CLASS = 'route-content';
const COLLAPSE_WIDTH_THRESHOLD = 600;

const FlightNav = (props) => {
  const {
    children, user, routes, logo, actionables,
    project, handleBackToProject, hasSidebar,
    handleSignOut, handleLogoClick, hasHeaderBorder,
    isActiveRouteExactMatch,
  } = props;
  const [isCollapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const handleWindowResize = () => {
    if ((isCollapsed && window.innerWidth >= COLLAPSE_WIDTH_THRESHOLD)
      || (!isCollapsed && window.innerWidth < COLLAPSE_WIDTH_THRESHOLD)) {
      setCollapsed(!isCollapsed);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  const handleSidebarToggleClick = () => {
    setCollapsed(!isCollapsed);
  };
  const isCurrentRouteSelected = (route) => {
    if (route.location === '/' || isActiveRouteExactMatch) {
      return location.pathname === route.location;
    }
    return location.pathname.includes(route.location);
  };
  const getRouteButton = (route) => {
    let routeButton = (
      <FlightButton
        label={isCollapsed ? '' : route.label}
        iconLeft={route.icon}
        className={isCurrentRouteSelected(route) ? 'selected' : ''}
        key={route.location}
        theme="secondary"
        onClick={() => history.push(route.location)}
      />
    );
    if (isCollapsed) {
      routeButton = (
        <FlightTooltip
          direction="right"
          description={route.label}
          key={route.location}
          isEnabled
        >
          {routeButton}
        </FlightTooltip>
      );
    }
    return routeButton;
  };
  let headerClass = HEADER_CLASS;
  headerClass += hasHeaderBorder ? ` ${HEADER_CLASS}--has-border` : '';
  let sidebarClass = SIDEBAR_CLASS;
  sidebarClass += isCollapsed ? ` ${SIDEBAR_CLASS}--collapsed` : '';
  sidebarClass += hasHeaderBorder ? ` ${SIDEBAR_CLASS}--has-border` : '';
  let routeContentClass = ROUTE_CONTENT_CLASS;
  routeContentClass += hasSidebar ? '' : ` ${ROUTE_CONTENT_CLASS}--without-sidebar`;
  routeContentClass += (hasSidebar && isCollapsed) ? ` ${ROUTE_CONTENT_CLASS}--collapsed` : '';
  return (
    <React.Fragment>
      <div className={DEFAULT_CLASS}>
        <div className={headerClass}>
          {hasSidebar && (
            <div className={`${HEADER_CLASS}__sidebar-toggle`}>
              <FlightButton
                theme="minor"
                iconRight="menu"
                onClick={handleSidebarToggleClick}
              />
            </div>
          )}
          {logo && (
            <div
              className={`${HEADER_CLASS}__logo`}
              onClick={handleLogoClick}
              onKeyPress={() => undefined}
              role="button"
              tabIndex="0"
              aria-label="nav-header-logo"
            >
              {logo}
            </div>
          )}
          {project && project.id && (
            <React.Fragment>
              <div className={`${HEADER_CLASS}__project`}>
                {project.image && (
                  <div className={`${HEADER_CLASS}__project__image`}>
                    {project.image}
                  </div>
                )}
                {project.name && (
                  <div className={`${HEADER_CLASS}__project__name`}>
                    {project.name}
                  </div>
                )}
                {project.environment && (
                  <div className={`${HEADER_CLASS}__project__environment`}>
                    (
                    {project.environment}
                    )
                  </div>
                )}
              </div>
              <FlightButton
                theme="minor"
                iconRight="collection"
                onClick={handleBackToProject}
              />
            </React.Fragment>
          )}
          <div className={`${HEADER_CLASS}__right`}>
            {actionables && (
              <div className={`${HEADER_CLASS}__actionables`}>
                {actionables}
              </div>
            )}
            {user && user.id && (
              <div className={`${HEADER_CLASS}__user`}>
                <FlightDropdown
                  className={`${HEADER_CLASS}__user__dropdown`}
                  trigger={(
                    <FlightAvatar
                      className={`${HEADER_CLASS}__user__avatar`}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      email={user.email}
                    />
                  )}
                >
                  <div className={`${HEADER_CLASS}__user__profile`}>
                    <FlightAvatar
                      className={`${HEADER_CLASS}__user__profile__avatar`}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      email={user.email}
                    />
                    {user.firstName && user.lastName ? (
                      <div className={`${HEADER_CLASS}__user__profile__name`}>
                        {`${user.firstName} ${user.lastName}`}
                      </div>
                    ) : null}
                    {user.email ? (
                      <div className={`${HEADER_CLASS}__user__profile__email`}>
                        {user.email}
                      </div>
                    ) : null}
                    {user.role ? (
                      <FlightTag
                        className={`${HEADER_CLASS}__user__profile__role`}
                        label={user.role}
                        type="info"
                      />
                    ) : null}
                    <FlightButton
                      className={`${HEADER_CLASS}__user__profile__signout`}
                      label="Sign out"
                      theme="secondary"
                      onClick={handleSignOut}
                    />
                  </div>
                </FlightDropdown>
              </div>
            )}
          </div>
        </div>
        {hasSidebar && (
          <div className={sidebarClass}>
            <div className={`${SIDEBAR_CLASS}__content`}>
              {routes.map((route) => (
                getRouteButton(route)
              ))}
            </div>
          </div>
        )}
        <div className={routeContentClass}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

FlightNav.propTypes = {
  children: PropTypes.element,
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
  }),
  routes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    location: PropTypes.string,
  })),
  actionables: PropTypes.element,
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.element,
    environment: PropTypes.string,
  }),
  logo: PropTypes.element,
  handleBackToProject: PropTypes.func,
  hasSidebar: PropTypes.bool,
  hasHeaderBorder: PropTypes.bool,
  handleSignOut: PropTypes.func.isRequired,
  handleLogoClick: PropTypes.func,
  isActiveRouteExactMatch: PropTypes.bool,
};

FlightNav.defaultProps = {
  children: null,
  user: null,
  routes: [],
  actionables: null,
  project: null,
  logo: null,
  handleBackToProject: () => undefined,
  hasSidebar: true,
  hasHeaderBorder: true,
  handleLogoClick: () => undefined,
  isActiveRouteExactMatch: false,
};

export default FlightNav;
