import React from 'react';
import Add from '../icons/Add';
import ArtificialIntelligence from '../icons/ArtificialIntelligence';
import BaselineArrowDown from '../icons/BaselineArrowDown';
import BaselineArrowLeft from '../icons/BaselineArrowLeft';
import BaselineArrowRight from '../icons/BaselineArrowRight';
import BaselineArrowUp from '../icons/BaselineArrowUp';
import BaselineCheck from '../icons/BaselineCheck';
import BaselineKeyboardArrowDown from '../icons/BaselineKeyboardArrowDown';
import BaselineKeyboardArrowLeft from '../icons/BaselineKeyboardArrowLeft';
import BaselineKeyboardArrowRight from '../icons/BaselineKeyboardArrowRight';
import BaselineKeyboardArrowUp from '../icons/BaselineKeyboardArrowUp';
import Bell from '../icons/Bell';
import BellAlarm from '../icons/BellAlarm';
import Blocked from '../icons/Blocked';
import Build from '../icons/Build';
import Camera from '../icons/Camera';
import CheckCircle from '../icons/CheckCircle';
import CheckboxCheck from '../icons/CheckboxCheck';
import CircleApps from '../icons/CircleApps';
import Clear from '../icons/Clear';
import ClockFill from '../icons/ClockFill';
import CloudFailed from '../icons/CloudFailed';
import Cog from '../icons/Cog';
import Collection from '../icons/Collection';
import ContactDetails from '../icons/ContactDetails';
import Counter from '../icons/Counter';
import CustomerInsight from '../icons/CustomerInsight';
import DataConfiguration from '../icons/DataConfiguration';
import DownCarrot from '../icons/DownCarrot';
import Download from '../icons/Download';
import Error from '../icons/Error';
import EyeHide from '../icons/EyeHide';
import EyeOpen from '../icons/EyeOpen';
import HelpOutline from '../icons/HelpOutline';
import House from '../icons/House';
import IndeterminateCheckbox from '../icons/IndeterminateCheckbox';
import InfoFilled from '../icons/InfoFilled';
import InfoStroke from '../icons/InfoStroke';
import Key from '../icons/Key';
import LeftCarrot from '../icons/LeftCarrot';
import Loading from '../icons/Loading';
import MathDivide from '../icons/MathDivide';
import MathExponent from '../icons/MathExponent';
import MathMinus from '../icons/MathMinus';
import MathModulo from '../icons/MathModulo';
import MathMultiply from '../icons/MathMultiply';
import MathPlus from '../icons/MathPlus';
import Menu from '../icons/Menu';
import MoreVert from '../icons/MoreVert';
import NodeConnect from '../icons/NodeConnect';
import OpenInNew from '../icons/OpenInNew';
import Pencil from '../icons/Pencil';
import People from '../icons/People';
import Person from '../icons/Person';
import PersonAdd from '../icons/PersonAdd';
import PhoneSetup from '../icons/PhoneSetup';
import PushNotification from '../icons/PushNotification';
import Remove from '../icons/Remove';
import Replay from '../icons/Replay';
import Report from '../icons/Report';
import RightCarrot from '../icons/RightCarrot';
import Search from '../icons/Search';
import Subject from '../icons/Subject';
import Tag from '../icons/Tag';
import Target from '../icons/Target';
import Template from '../icons/Template';
import TextBox from '../icons/TextBox';
import TrashCan from '../icons/TrashCan';
import UpCarrot from '../icons/UpCarrot';
import UserAttributes from '../icons/UserAttributes';
import Warning from '../icons/Warning';
import Workflow from '../icons/Workflow';

const getIcon = (iconName, props) => {
  const ICONS = {
    add: <Add {...props} />,
    artificialIntelligence: <ArtificialIntelligence {...props} />,
    baselineArrowDown: <BaselineArrowDown {...props} />,
    baselineArrowLeft: <BaselineArrowLeft {...props} />,
    baselineArrowRight: <BaselineArrowRight {...props} />,
    baselineArrowUp: <BaselineArrowUp {...props} />,
    baselineCheck: <BaselineCheck {...props} />,
    baselineKeyboardArrowDown: <BaselineKeyboardArrowDown {...props} />,
    baselineKeyboardArrowLeft: <BaselineKeyboardArrowLeft {...props} />,
    baselineKeyboardArrowRight: <BaselineKeyboardArrowRight {...props} />,
    baselineKeyboardArrowUp: <BaselineKeyboardArrowUp {...props} />,
    bell: <Bell {...props} />,
    bellAlarm: <BellAlarm {...props} />,
    blocked: <Blocked {...props} />,
    build: <Build {...props} />,
    camera: <Camera {...props} />,
    checkCircle: <CheckCircle {...props} />,
    checkboxCheck: <CheckboxCheck {...props} />,
    circleApps: <CircleApps {...props} />,
    clear: <Clear {...props} />,
    clockFill: <ClockFill {...props} />,
    cloudFailed: <CloudFailed {...props} />,
    cog: <Cog {...props} />,
    collection: <Collection {...props} />,
    contactDetails: <ContactDetails {...props} />,
    counter: <Counter {...props} />,
    customerInsight: <CustomerInsight {...props} />,
    dataConfiguration: <DataConfiguration {...props} />,
    downCarrot: <DownCarrot {...props} />,
    download: <Download {...props} />,
    error: <Error {...props} />,
    eyeHide: <EyeHide {...props} />,
    eyeOpen: <EyeOpen {...props} />,
    helpOutline: <HelpOutline {...props} />,
    house: <House {...props} />,
    indeterminateCheckbox: <IndeterminateCheckbox {...props} />,
    infoFilled: <InfoFilled {...props} />,
    infoStroke: <InfoStroke {...props} />,
    key: <Key {...props} />,
    leftCarrot: <LeftCarrot {...props} />,
    loading: <Loading {...props} />,
    mathDivide: <MathDivide {...props} />,
    mathExponent: <MathExponent {...props} />,
    mathMinus: <MathMinus {...props} />,
    mathModulo: <MathModulo {...props} />,
    mathMultiply: <MathMultiply {...props} />,
    mathPlus: <MathPlus {...props} />,
    menu: <Menu {...props} />,
    moreVert: <MoreVert {...props} />,
    nodeConnect: <NodeConnect {...props} />,
    openInNew: <OpenInNew {...props} />,
    pencil: <Pencil {...props} />,
    people: <People {...props} />,
    person: <Person {...props} />,
    personAdd: <PersonAdd {...props} />,
    phoneSetup: <PhoneSetup {...props} />,
    pushNotification: <PushNotification {...props} />,
    remove: <Remove {...props} />,
    replay: <Replay {...props} />,
    report: <Report {...props} />,
    rightCarrot: <RightCarrot {...props} />,
    search: <Search {...props} />,
    subject: <Subject {...props} />,
    tag: <Tag {...props} />,
    target: <Target {...props} />,
    template: <Template {...props} />,
    textBox: <TextBox {...props} />,
    trashCan: <TrashCan {...props} />,
    upCarrot: <UpCarrot {...props} />,
    userAttributes: <UserAttributes {...props} />,
    warning: <Warning {...props} />,
    workflow: <Workflow {...props} />,
  };
  return ICONS[iconName];
};

export default getIcon;
